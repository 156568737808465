import axios from 'axios';

export default class NetworkAdmin {
  static getAdminAssessmentSchemas(config) {
    return axios.get('admin/assessmentSchemas', config);
  }

  static getAdminAssessment(config) {
    return axios.get('admin/assessments', config);
  }

  static getAdminContentElement(config) {
    return axios.get('admin/contentElement', config);
  }

  static getAdminConfigElement(config) {
    return axios.get('admin/configElement', config);
  }

  static getAdminUserElements(config) {
    return axios.get('admin/userElements', config);
  }

  static async saveAdminAssessmentSchemas(data, config) {
    return axios.post('admin/assessmentSchemas', { data }, config);
  }

  static async saveAdminAssessment(data, config) {
    return axios.post('admin/assessments', { data }, config);
  }

  static async saveAdminConfigElement(data, config) {
    return axios.post('admin/configElement', { data }, config);
  }

  static async saveAdminContentElement(data, config) {
    return axios.post('admin/contentElement', { data }, config);
  }

  static async saveAdminUserElements(data, config) {
    return axios.post('admin/userElements', { data }, config);
  }
}
