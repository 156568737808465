<template>
  <div class="overlay-container" v-if="getVisibleOverlays">
    <component :is="getOverlayComponent(getOverlay)"
               :data="getOverlay"/>
  </div>
</template>

<script>
import IntroKssTrackerOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/IntroKssTrackerOverlayComponent.vue';
import FeedbackOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/FeedbackOverlayComponent.vue';
import DevTipLibraryOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/DevTipLibraryOverlayComponent.vue';
import SimilarKssWarningOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/SimilarKssWarningOverlayComponent.vue';

export default {
  name: 'FrontendOverlayComponent',
  components: {
    SimilarKssWarningOverlayComponent,
    IntroKssTrackerOverlayComponent,
    DevTipLibraryOverlayComponent,
    FeedbackOverlayComponent,
  },
  computed: {
    getVisibleOverlays() {
      return this.$store.getters.visibleFrontendOverlays;
    },
    getOverlay() {
      if (this.$store.getters.getFrontendOverlays) {
        return this.$store.getters.getFrontendOverlays[0];
      }

      return null;
    },
  },
  methods: {
    getOverlayComponent(overlay) {
      switch (overlay.type) {
        case 'introKssTracker':
          return 'IntroKssTrackerOverlayComponent';
        case 'devTipLibrary':
          return 'DevTipLibraryOverlayComponent';
        case 'feedback':
          return 'FeedbackOverlayComponent';
        case 'similarKssTasks':
          return 'SimilarKssWarningOverlayComponent';
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.5);
}

</style>
