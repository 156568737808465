<template>
  <div class="overview-item">
    <div class="icon-wrapper" @click.prevent="markTip">
      <font-awesome-icon v-if="!tip.done" class="unchecked-icon"
                         icon="fa-regular fa-square"/>
      <font-awesome-icon v-if="tip.done" class="checked-icon" icon="fa-square-check"/>
      <font-awesome-icon v-if="tip.new" class="circle-icon-notification"
                         icon="fa-solid fa-circle"/>
    </div>
    <div class="overview-content"
         @click="showFeedbackPopup = !showFeedbackPopup">
      <p class="overview-title"><b>{{ tip.title?.[getLocale] ?? '-' }}</b></p>
      <div class="overview-details">
        <p>{{ tip.dimension?.[getLocale] ?? '-' }}</p>
        <p>{{ $t('DevTipItemComponent.recievedAt') }} {{ getDate }}</p>
      </div>
      <p v-if="showText">{{ tip.text?.[getLocale] ?? '-' }}</p>
      <div class="feedback-popup" v-show="showFeedback">
        <h4>{{ $t('DevTipItemComponent.wasThisTipHelpful') }}</h4>
        <div class="feedback-buttons">
          <font-awesome-icon class="thumbs-up" icon="fa-solid fa-thumbs-up"
                             @click.prevent="sumbitFeedback(true, tip.text)"/>
          <font-awesome-icon class="thumbs-down" icon="fa-solid fa-thumbs-down"
                             @click.prevent="sumbitFeedback(false, tip.text)"/>
        </div>
      </div>
    </div>
  </div>
  <div class="divider"></div>
</template>

<script>
export default {
  name: 'DevTipItemComponent',
  props: {
    tip: {
      type: Object,
      default: null,
    },
    showText: {
      type: Boolean,
      default: false,
    },
    overlayId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showFeedbackPopup: false,
    };
  },
  computed: {
    getDate() {
      const date = new Date(this.tip.date);
      return `${(`0${date.getDate()}`).slice(-2)}.${(`0${date.getMonth() + 1}`).slice(-2)}.${date.getFullYear()}`;
    },
    getLocale() {
      return this.$i18n.locale;
    },
    showFeedback() {
      return this.showFeedbackPopup && !this.tip.feedbackGiven;
    },
  },
  methods: {
    async markTip() {
      try {
        await this.$store.dispatch('setNoticeDevTipp', { id: this.tip?.tip_id });
        await this.$store.dispatch('setTippDone', {
          id: this.tip?.tip_id,
          done: !this.tip?.done,
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('DevTipItemComponent.errorCouldSetDone'),
          type: 'error',
        });
      }
    },
    sumbitFeedback(helpful, text = '') {
      try {
        this.$store.commit('addFrontendOverlay', {
          id: Math.random(),
          type: 'feedback',
          notification: {
            content: {
              helpful,
              text,
              tipId: this.tip?.tip_id,
            },
          },
        });
        this.$store.commit('setShowDevTipps', false);
        if (this.overlayId) {
          this.$store.commit('removeFrontendOverlay', this.overlayId);
        }
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('DevTipItemComponent.errorCouldNotFeedback'),
          type: 'error',
        });
      }
    },
  },
};
</script>

<style scoped>
.overview-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: .5em;
  margin-top: .5rem;
}

.overview-item .unchecked-icon,
.overview-item .checked-icon {
  margin-right: .5em;
  align-self: center;
}

.icon-wrapper {
  position: relative;
  display: inline-block;
  z-index: 10;
  cursor: pointer;
}

.checked-icon {
  color: var(--color-orange);
  font-size: 40px;
}

.unchecked-icon {
  font-size: 40px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #ccc; /* or any other color you prefer */
  margin: 1em 0; /* adjust the space around the divider if needed */
}

.circle-icon-notification {
  position: absolute;
  top: -5px;
  left: 24px;
  font-size: 16px;
  color: var(--color-orange);
}

.overview-content {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.overview-content.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.overview-content * {
  margin: 0;
  padding: 0;
}

.overview-title {
  margin-bottom: 0.1em;
}

.overview-details {
  display: flex;
  font-size: 12px;
}

.overview-details p:first-child {
  color: #F49718;
  margin-right: 1em;
}

.overview-details p:last-child {
  color: #999999;
}

.feedback-popup {
  position: absolute;
  background-color: black;
  color: white;
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  width: max-content;
}

.feedback-popup h4 {
  margin-bottom: 0.5em;
}

.feedback-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.thumbs-up,
.thumbs-down {
  cursor: pointer;
  font-size: 20px;
  flex: 1;
  text-align: center;
}

.thumbs-up:hover,
.thumbs-down:hover {
  color: var(--color-orange);
}

</style>
