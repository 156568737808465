import { createRouter, createWebHistory } from 'vue-router';
import AuthView from '@/views/AuthView.vue';
import LoginComponent from '@/components/Auth/LoginComponent.vue';
import RegisterComponent from '@/components/Auth/RegisterComponent.vue';
import PasswordResetComponent from '@/components/Auth/PasswordResetComponent.vue';
import AuthTeamComponent from '@/components/Auth/AuthTeamComponent.vue';
import { checkIfRegisterDataCorrect } from '@/router/middleware/checkIfRegisterDataCorrect';
import SuccessEmailResetComponent from '@/components/Auth/SuccessEmailResetComponent.vue';
import { checkIfPasswordResetRequested } from '@/router/middleware/checkIfPasswordResetRequested';
import SuccessRegistrationComponent from '@/components/Auth/SuccessRegistrationComponent.vue';
import { checkIfRegisterSuccessful } from '@/router/middleware/checkIfRegisterSuccessful';
import HomeComponent from '@/components/Home/HomeComponent/HomeComponent.vue';
import KssCreatorComponent from '@/components/Home/KssCreator/KssCreatorComponent.vue';
import QuestionnaireView from '@/views/QuestionnaireView.vue';
import QuestionnaireComponent
  from '@/components/Home/QuestionnaireComponent/QuestionnaireComponent.vue';
import TeamAdministrationComponent
  from '@/components/Home/TeamAdministration/TeamAdministrationComponent.vue';
import OimIntroComponent from '@/components/Home/QuestionnaireComponent/OimIntroComponent.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import DashboardComponent from '@/components/Home/DashboardComponent/DashboardComponent.vue';
import ActivateAccountComponent from '@/components/Auth/ActivateAccountComponent.vue';
import EditProfileComponent from '@/components/Home/EditProfileComponent/EditProfileComponent.vue';
import TeamDashboardComponent
  from '@/components/Home/DashboardComponent/TeamDashboardComponent.vue';
import AdminComponent from '@/components/Home/AdminComponent/AdminComponent.vue';
import { checkIfUserHasPermission } from '@/router/middleware/checkIfUserHasPermission';
import KnowledgeDatabaseComponent
  from '@/components/Home/KnowledgeDatabaseComponent/KnowledgeDatabaseComponent.vue';
import AuthTagSelectComponent from '@/components/Auth/AuthTagSelectComponent.vue';
import SuccessPasswordChangeComponent from '@/components/Auth/SuccessPasswordChangeComponent.vue';
import { checkIfNewPasswordSuccessful } from '@/router/middleware/checkIfNewPasswordSuccessful';
import NewPasswordComponent from '@/components/Auth/NewPasswordComponent.vue';
import LogoutComponent from '@/components/Auth/LogoutComponent.vue';
import { checkIfUserLoggedIn } from '@/router/middleware/checkIfUserLoggedIn';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'homeView',
    component: HomeView,
    meta: { requiresAuth: true },
    beforeEnter: checkIfUserLoggedIn,
    children: [
      {
        name: 'home',
        path: '',
        component: HomeComponent,
      },
      {
        name: 'kssCreator',
        path: '/kss-creator',
        component: KssCreatorComponent,
        props: (route) => ({ tab: route.query.tab }),
      },
      {
        name: 'teamAdministration',
        path: 'team-administration',
        component: TeamAdministrationComponent,
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: DashboardComponent,
        props: (route) => ({ tab: route.query.tab }),
      },
      {
        name: 'knowledgeDatabase',
        path: 'knowledge-database',
        component: KnowledgeDatabaseComponent,
      },
      {
        name: 'teamDashboard',
        path: 'team-dashboard',
        component: TeamDashboardComponent,
        beforeEnter: (to, from, next) => checkIfUserHasPermission(to, from, next, ['admin', 'companySuperuser', 'teamLeader']),
        props: (route) => ({ tab: route.query.tab }),
      },
      {
        name: 'editProfile',
        path: 'edit-profile',
        component: EditProfileComponent,
      },
      {
        name: 'admin',
        path: 'admin',
        component: AdminComponent,
        beforeEnter: (to, from, next) => checkIfUserHasPermission(to, from, next, ['admin']),
      },
      {
        name: 'oimIntro',
        path: 'oim-intro',
        component: OimIntroComponent,
        props: (route) => ({ id: route.query.id }),
      },
    ],
  },
  {
    name: 'evaluation',
    path: '/evaluation/questionnaire',
    component: QuestionnaireView,
    children: [
      {
        name: 'questionnaireEvaluation',
        path: '',
        component: QuestionnaireComponent,
        props: (route) => ({ id: route.query.id }),
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    children: [
      {
        name: 'login',
        path: '',
        component: LoginComponent,
      },
      {
        name: 'logout',
        path: '/auth/logout',
        component: LogoutComponent,
      },
      {
        name: 'register',
        path: '/auth/register',
        component: RegisterComponent,
      },
      {
        name: 'passwordReset',
        path: '/auth/password-reset',
        component: PasswordResetComponent,
      },
      {
        name: 'newPassword',
        path: '/auth/new-password',
        component: NewPasswordComponent,
        props: (route) => ({
          validationKey: route.query.key,
          email: route.query.mail,
        }),
      },
      {
        name: 'teamSelection',
        path: '/auth/team-selection',
        component: AuthTeamComponent,
        beforeEnter: checkIfRegisterDataCorrect,
      },
      {
        name: 'tagSelection',
        path: '/auth/tag-selection',
        component: AuthTagSelectComponent,
        beforeEnter: checkIfRegisterDataCorrect,
      },
      {
        name: 'passwordResetSuccess',
        path: '/auth/password-reset-success',
        component: SuccessEmailResetComponent,
        beforeEnter: checkIfPasswordResetRequested,
      },
      {
        name: 'registerSuccessful',
        path: '/auth/register-success',
        component: SuccessRegistrationComponent,
        beforeEnter: checkIfRegisterSuccessful,
      },
      {
        name: 'newPasswordSuccess',
        path: '/auth/new-password-success',
        component: SuccessPasswordChangeComponent,
        beforeEnter: checkIfNewPasswordSuccessful,
      },
      {
        name: 'userActivation',
        path: '/auth/account-activation',
        component: ActivateAccountComponent,
        props: (route) => ({
          email: route.query.mail,
          validationKey: route.query.key,
        }),
      },
    ],
  },
  {
    path: '/impressum',
    name: 'impressum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ImpressumView" */ '../views/ImpressumView.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ImpressumView" */ '../views/PrivacyView.vue'),
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
