<template>
  <div class="recurring_task">
    <div class="checkbox-container">
      <input v-model="isRecurringTask" type="checkbox" id="rec_box">
      <label for="rec_box" class="rec_label" id="lbl_recBox2">
        {{ $t('KssRecurringTaskComponent.isRecurringTask') }}
      </label>
    </div>
    <div v-if="isRecurringTask" class="drop_event" id="drop_event">
      <div class="drop_container">
        <div class="drop_select_event">
          <select v-model="type" name="eventtime" id="select_event" class="event">
            <option value="daily">
              {{ $t('KssRecurringTaskComponent.daily') }}
            </option>
            <option value="weekly">
              {{ $t('KssRecurringTaskComponent.weekly') }}
            </option>
            <option value="monthly">
              {{ $t('KssRecurringTaskComponent.monthly') }}
            </option>
            <option value="custom">
              {{ $t('KssRecurringTaskComponent.custom') }}
            </option>
          </select>
        </div>
        <div v-if="type === 'custom'" class="custom_choice" id="custom_choice">
          <label for="nmbr" class="nmbr_label">
            {{ $t('KssRecurringTaskComponent.repeatEvery') }}
          </label>
          <input v-model="everyX" type="number" min="1" name="nmbr" class="nmbr">
          <select v-model="subtype" name="nmbrevent" id="nmbr_event" class="event">
            <option value="days">
              {{ $t('KssRecurringTaskComponent.days') }}
            </option>
            <option value="weeks">
              {{ $t('KssRecurringTaskComponent.weeks') }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="type === 'weekly' || subtype === 'weeks'" class="seven_days" id="seven_days">
        <label id="day" v-for="(day, index) in getDays" :key="day.name" @click="selectDay(index)">
          <div class="content-day" :class="{checked: day.isSelected}">
            <span>{{ $t('KssRecurringTaskComponent.daysShort.' + day.rSchName) }}</span>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KssRecurringTaskComponent',
  data() {
    return {
      type: this.$store.state.kss.recurringType,
      subtype: this.$store.state.kss.recurringSubtype,
      everyX: this.$store.state.kss.recurringEveryX,
    };
  },
  computed: {
    isRecurringTask: {
      get() {
        return this.$store.getters.getIsRecurringTask;
      },
      set(newValue) {
        this.$store.commit('setIsRecurringTask', newValue);
      },
    },
    getDays() {
      return this.$store.getters.getRecurringDays;
    },
  },
  methods: {
    selectDay(index) {
      this.$store.commit('selectRecurringDate', index);
    },
  },
  watch: {
    type(value) {
      this.$store.commit('setRecurringType', value);
      this.subtype = 'days';
      this.everyX = 1;
    },
    subtype(value) {
      this.$store.commit('setRecurringSubtype', value);
    },
    everyX(value) {
      this.$store.commit('setRecurringEveryX', value);
    },
  },
  mounted() {
    this.$store.commit('initRecurringDays');
  },
};
</script>

<style scoped>
/*---------------- CONTENT NEW: RECURRING TASKS ------------------*/
.recurring_task {
  display: block;
}

.recurring_task .checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recurring_task .checkbox-container .rec_label {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recurring_task .checkbox-container input[type='checkbox']#rec_box {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.recurring_task .checkbox-container .rec_label::before {
  content: '';
  width: 1em;
  height: 1em;
  border-radius: .15em;
  margin-right: .5em;
  border: .05em solid black;
}

.recurring_task .checkbox-container .rec_label:hover::before,
.recurring_task .checkbox-container input[type='checkbox']#rec_box:hover + label::before {
  border-color: var(--color-orange);
}

.recurring_task .checkbox-container input[type='checkbox']#rec_box:checked + label::before {
  content: '\002714';
  background-color: var(--color-orange);
  border-color: var(--color-orange);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.drop_event {
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5em;
}

.drop_event .drop_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

select#select_event {
  width: 196px;
  height: 40px;
  margin-top: 10px;
}

select#nmbr_event {
  width: 81px;
  height: 40px;
}

select {
  height: 100%;
  font-weight: 300;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  color: var(--color-orange);
  background-color: var(--color-white);
  box-shadow: 5px 10px 8px var(--color-light-grey);
  cursor: pointer;
}

select option {
  width: 150px;
  height: 30px;
  font-size: 14px;
  padding: 10px;
  margin: 10px;
  border: none;
  color: var(--color-orange);
  background-color: var(--color-milk);
  cursor: pointer;
}

label {
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 18px;
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
}

.custom_choice {
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
}

.nmbr_label {
  padding-right: 10px;
}

input[type="number"].nmbr {
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--color-light-grey);
  width: 70px;
  margin-right: 5px;
  font-size: 15px;
  padding-left: 10px;
  height: 3rem;
  background: transparent;
  box-sizing: border-box;
}

.seven_days {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 45px;
}

.seven_days label {
  display: flex;
  position: relative;
  margin: 5px;
  font-size: 13px;
  width: fit-content;
}

.seven_days label#mon {
  margin-left: 0;
}

.seven_days label .content-day {
  display: flex;
  padding: 15px;
  width: 100%;
  border: 1px solid var(--color-light-grey);
  border-radius: 10px;
  margin: 0px;
  cursor: pointer;
}

.seven_days span {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.seven_days .checked span {
  font-weight: 600;
}

.seven_days .checked .content-day .icon {
  display: block;
}

.seven_days .content-day .icon {
  position: absolute;
  top: 0px;
  right: 7px;
}

.seven_days .checked {
  color: var(--color-orange);
  background-color: var(--color-bisque);
  border: var(--color-orange);
}

</style>
