<template>
  <div class="container">
    <div class="forms">
      <img class="platform-logo" src="@/assets/logo_transparent.png" alt="test">
      <h1>{{ $t('LoginComponent.welcomeMessage') }}</h1>
      <vee-form :validation-schema="schema" @submit="login" v-slot="{ errors }">
        <div class="input-field">
          <vee-field type="text" name="email" id="txt_mail"
                     :placeholder="$t('LoginComponent.placeholders.enterEmail')"
                     :class="{inputError : !!errors.email}"/>
        </div>
        <error-message class="error-message" name="email"></error-message>
        <div class="input-field">
          <vee-field :type="toggleInput" name="password" id="txt_password"
                     :placeholder="$t('LoginComponent.placeholders.password')"
                     :class="{inputError : !!errors.password}"/>
          <i class="togglePassword" @click.prevent="showPassword = !showPassword">
            <font-awesome-icon :icon="passwordIconToDisplay"
                               id="icn_toggle"/>
          </i>
        </div>
        <error-message class="error-message" name="password"></error-message>
        <div class="pass" id="btn_forgotPW">
          <router-link class="link" to="/auth/password-reset">
            {{ $t('LoginComponent.forgotPassword') }}
          </router-link>
        </div>
        <language-component class="lang-component" v-if="true"/>
        <button class="button" type="submit" name="login" id="btn_login" :disabled="isSubmitting">
          {{ $t('LoginComponent.login') }}
        </button>
        <div class="dont-account">
          {{ $t('LoginComponent.dontHaveAnAccount') }}
          <router-link class="link" to="/auth/register" id="btn_noAccount">
            {{ $t('LoginComponent.signup') }}
          </router-link>
        </div>
      </vee-form>
    </div>
  </div>
</template>

<script>
import LanguageComponent from '@/components/global/LanguageComponent.vue';

export default {
  name: 'LoginComponent',
  components: { LanguageComponent },
  data() {
    return {
      showPassword: false,
      schema: {
        email: 'required|email',
        password: 'required',
      },
      isSubmitting: false,
    };
  },
  computed: {
    toggleInput() {
      return this.showPassword ? 'text' : 'password';
    },
    passwordIconToDisplay() {
      return this.showPassword ? 'fa-eye-slash' : 'fa-eye';
    },
  },
  methods: {
    async login(values) {
      try {
        this.isSubmitting = true;
        await this.$store.dispatch('login', values);
        if (this.$store.getters.isLoggedIn) {
          this.$router.push('/');
        }
      } catch (error) {
        this.isSubmitting = false;
        // Todo handle errors
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style scoped>
/*------------ GENERAL ----------------*/
.lang-component {
  float: left;
  margin-top: 5px;
  position: absolute;
}

h1 {
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
  padding: 0 0 20px 0;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

.container .forms {
  padding: 30px;
}

.error-message {
  margin-top: 2px;
}

.platform-logo {
  display: block;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  width: 10rem;
  height: auto;
}

.forms .input-field {
  position: relative;
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid #E7E7E7;
}

.input-field input {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  font-size: 16px;
  background-color: rgb(0, 0, 0, 0);
}

.input-field i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.input-field i.togglePassword {
  right: 10px;
  color: var(--color-disabled);
}

.input-field .inputError {
  border: 2px solid var(--color-error);
}

.pass {
  float: right;
  cursor: pointer;
  color: var(--color-orange);
  font-weight: 600;
  padding: 15px 10px;
}

.pass:hover {
  color: var(--color-fulvous);
}

.pass i {
  padding: 0 .5rem;
}

/*------------------ BUTTONS ------------------*/
.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.button:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

button[type=submit]:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.dont-account {
  text-align: center;
  padding: 2rem 0 3rem 0;
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 500px) {
  .container {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
}

</style>
