<template>
  <div class="member-container">
    <div class="member">
      <img :src="getProfilePic" alt="" class="photo">
      <span class="name">{{ member?.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberComponent',
  props: ['member'],
  data() {
    return {
      refreshValue: this.$store.state.content.refreshValue,
    };
  },
  computed: {
    getRefreshValue() {
      return this.$store.getters.getRefreshValue;
    },
    getProfilePic() {
      // eslint-disable-next-line global-require
      if (this.member.picture_profile) {
        return `${this.member.picture_profile}?_${this.getRefreshValue}`;
      }

      // eslint-disable-next-line global-require
      return require('@/assets/smiley-profil-bild.png');
    },
  },
};
</script>

<style scoped>

.member-container {
  display: block;
}

.member .photo {
  width: 5rem;
  border-radius: 50%;
  overflow: hidden;
}

.member .name {
  padding: 0 5px 5px;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 18px;
  text-align: center;
}

</style>
