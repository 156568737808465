<template>
  <li :class="getItemClass">
    <span class="item-text">
      <img class="profile-photo"
           src="@/assets/smiley-profil-bild.png" alt="profile-photo">
      <div class="name">
        <div>{{ user.firstName }}</div>
        <div>{{ user.lastName }}</div>
      </div>
    </span>
    <span class="checkbox">
      <i class="check-icon">
        <font-awesome-icon icon="fa-circle-check"/>
      </i>
    </span>
  </li>
</template>

<script>
export default {
  name: 'UserComponent',
  props: ['user'],
  computed: {
    getItemClass() {
      return this.user.isChecked ? 'item checked' : 'item';
    },
  },
};
</script>

<style scoped>

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
}

.item .item-text .name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-items .item:hover {
  transform: scale(1.02);
}

.list-items .item .item-text {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 24px;
  transform: scale(0);
  transition: all 0.2s ease-in-out;
}

.item.checked .check-icon {
  transform: scale(1);
}
</style>
