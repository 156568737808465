<template>
  <breadcrumbs-component :breadCrumbs="adminBreadCrumbs"/>
  <div>
    <button class="round-button"
            :disabled="isLoading"
            :class="{selected: state === 'schemas'}"
            @click="getSchemas">
      Schemas
    </button>
    <button class="round-button"
            :disabled="isLoading"
            :class="{selected: state === 'assessments'}"
            @click="getAssessments">
      Assessments
    </button>
    <button class="round-button"
            :disabled="isLoading"
            :class="{selected: state === 'config'}"
            @click="getConfig">
      Config
    </button>
    <button class="round-button"
            :disabled="isLoading"
            :class="{selected: state === 'content'}"
            @click="getContent">
      Content
    </button>
    <button class="round-button"
            :disabled="isLoading"
            :class="{selected: state === 'user'}"
            @click="getUser">
      User
    </button>
  </div>
  <div class="monaco-container">
    <div ref="container" class="monaco-editor"></div>
  </div>
  <div class="admin_btns">
    <button class="btn" :disabled="isLoading" id="btn_safeUser" @click.prevent="save">
      Save
    </button>
  </div>
</template>

<script>
// TODO TRANSLATE EVERYTHING
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
// eslint-disable-next-line import/extensions
import * as monaco from 'monaco-editor';
import NetworkAdmin from '@/helpers/networking/NetworkAdmin';

export default {
  name: 'AdminComponent',
  components: { BreadcrumbsComponent },
  data() {
    return {
      state: null,
      isLoading: false,
      initContent: '',
      initData: null,
      latestDbContent: null,
      adminBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/admin',
          isActive: true,
          name: this.$t('BreadCrumbs.admin'),
        },
      ],
    };
  },
  methods: {
    async getSchemas() {
      try {
        this.isLoading = true;
        const { data } = await NetworkAdmin.getAdminAssessmentSchemas();
        this.isLoading = false;
        this.initEditor(data.data, 'schemas');
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
      }
    },
    async getAssessments() {
      try {
        this.isLoading = true;
        const { data } = await NetworkAdmin.getAdminAssessment();
        this.isLoading = false;
        this.initEditor(data.data, 'assessments');
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
      }
    },
    async getConfig() {
      try {
        this.isLoading = true;
        const { data } = await NetworkAdmin.getAdminConfigElement();
        this.isLoading = false;
        this.initEditor(data.data, 'config');
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
      }
    },
    async getContent() {
      try {
        this.isLoading = true;
        const { data } = await NetworkAdmin.getAdminContentElement();
        this.isLoading = false;
        this.initEditor(data.data, 'content');
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
      }
    },
    async getUser() {
      try {
        this.isLoading = true;
        const { data } = await NetworkAdmin.getAdminUserElements();
        this.isLoading = false;
        this.initEditor(data.data, 'user');
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
      }
    },
    initEditor(json, state) {
      const initData = JSON.stringify(json, null, '\t');
      this.editor.setValue(initData);
      this.initContent = initData;
      this.initData = json;
      this.state = state;
    },
    async saveSchemas() {
      try {
        const data = JSON.parse(this.editor.getValue());
        this.isLoading = true;
        await NetworkAdmin.saveAdminAssessmentSchemas(data);
        this.isLoading = false;
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
      }
    },
    async saveAssessments() {
      try {
        const data = JSON.parse(this.editor.getValue());
        this.isLoading = true;
        await NetworkAdmin.saveAdminAssessment(data);
        this.isLoading = false;
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
      }
    },
    async saveConfig() {
      try {
        const data = JSON.parse(this.editor.getValue());
        this.isLoading = true;
        await NetworkAdmin.saveAdminConfigElement(data);
        this.isLoading = false;
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
      }
    },
    async saveContent() {
      try {
        const data = JSON.parse(this.editor.getValue());
        this.isLoading = true;
        await NetworkAdmin.saveAdminContentElement(data);
        this.isLoading = false;
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
      }
    },
    async saveUser() {
      try {
        const data = JSON.parse(this.editor.getValue());
        this.isLoading = true;
        await NetworkAdmin.saveAdminUserElements(data);
        this.isLoading = false;
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
      }
    },
    async save() {
      if (this.hasErrors()) {
        alert('Content has Errors');
        return;
      }

      if (!this.hasChanges()) {
        alert('No Changes');
        return;
      }

      this.isLoading = true;
      const hasChange = await this.hasChangesInDB();
      this.isLoading = false;
      if (hasChange) {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('The database data has been changed do you want to continue?')) {
          return;
        }
      }

      try {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you want to submit these changes?')) {
          this.isLoading = true;
          await this.callSavedMethod();
          this.$store.commit('setToastNotification', {
            id: Math.random(),
            message: 'Changes Saved!',
            type: 'success',
          });
          await this.callGetInitMethod();
          this.isLoading = false;
        }
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
      }
    },
    hasChanges() {
      return this.initContent !== this.editor.getValue();
    },
    hasErrors() {
      const errors = monaco.editor.getModelMarkers({ resource: this.editor });
      return errors.length !== 0;
    },
    async hasChangesInDB() {
      try {
        this.isLoading = true;
        await this.callGetForLatestDbDataMethod();
        this.isLoading = false;
        return JSON.stringify(this.initData) !== JSON.stringify(this.latestDbContent);
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
        console.log(error);
        this.isLoading = false;
        return true;
      }
    },
    async callSavedMethod() {
      // eslint-disable-next-line default-case
      switch (this.state) {
        case 'schemas':
          await this.saveSchemas();
          break;
        case 'assessments':
          await this.saveAssessments();
          break;
        case 'config':
          await this.saveConfig();
          break;
        case 'content':
          await this.saveContent();
          break;
        case 'user':
          await this.saveUser();
          break;
      }
    },
    async callGetInitMethod() {
      // eslint-disable-next-line default-case
      switch (this.state) {
        case 'schemas':
          await this.getSchemas();
          break;
        case 'assessments':
          await this.getAssessments();
          break;
        case 'config':
          await this.getConfig();
          break;
        case 'content':
          await this.getContent();
          break;
        case 'user':
          await this.getUser();
          break;
      }
    },
    async callGetForLatestDbDataMethod() {
      // eslint-disable-next-line default-case
      let data;
      switch (this.state) {
        case 'schemas':
          ({ data } = await NetworkAdmin.getAdminAssessmentSchemas());
          this.latestDbContent = data.data;
          break;
        case 'assessments':
          ({ data } = await NetworkAdmin.getAdminAssessment());
          this.latestDbContent = data.data;
          break;
        case 'config':
          ({ data } = await NetworkAdmin.getAdminConfigElement());
          this.latestDbContent = data.data;
          break;
        case 'content':
          ({ data } = await NetworkAdmin.getAdminContentElement());
          this.latestDbContent = data.data;
          break;
        case 'user':
          ({ data } = await NetworkAdmin.getAdminUserElements());
          this.latestDbContent = data.data;
          break;
        default:
          console.log('Unkown State!');
      }
    },
  },
  mounted() {
    this.editor = monaco.editor.create(this.$refs.container, {
      value: this.code,
      language: 'json',
      theme: 'vs-dark',
    });
  },
  unmounted() {
    this.editor.dispose();
  },
};
</script>

<style scoped>
.monaco-container {
  margin-top: 20px;
  height: 800px;
  width: 1200px;
}

.monaco-editor {
  height: 100%;
  width: 100%;
}

#monaco-container {
  margin-top: 20px;
  height: 800px;
  width: 800px;
}

.admin-container {
  margin: 0 auto;
  position: relative;
}

.admin_btns {
  display: flex;
  position: relative;
  margin: 3rem 0;
  box-sizing: border-box;
}

.round-button {
  border-radius: 20px;
  padding: 10px 20px;
  margin-right: 10px;
  background-color: var(--color-bisque);
  border: none;
  cursor: pointer;
}

.round-button.selected {
  background-color: var(--color-fulvous);
}

.round-button:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.btn {
  padding: 5px;
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}
</style>
