import axios from 'axios';
import store from '@/store';
import keycloak from '../../keycloak';

export default {
  install() {
    axios.defaults.baseURL = process.env.VUE_APP_OMIND_BACKEND;
    if (process.env.VUE_APP_KEYCLOAK === 'true') {
      axios.interceptors.request.use((config) => {
        if (keycloak.authenticated) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${keycloak.token}`;
        }
        return config;
      });
    } else {
      const token = store.state.auth.token ?? localStorage.getItem('jwt');
      axios.defaults.headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
    }
  },
};
