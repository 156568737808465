<template>
  <div class="icon-container" :class="{ 'hovered': isHovered }"
       @click="toggleShowDevTipps"
       @mouseover="isHovered = true"
       @mouseleave="isHovered = false"
       @focusin="isHovered = true"
       @focusout="isHovered = false">
    <font-awesome-icon :class="{ 'glowing': hasNew }" icon="fa-regular fa-lightbulb"/>
    <font-awesome-icon v-if="hasNew" class="circle-icon" icon="fa-circle"/>
  </div>
  <dev-tip-item-container-component v-if="getShowDevTipps"/>
</template>

<script>
import DevTipItemContainerComponent
  from '@/components/Home/Shared/DevTip/DevTipItemContainerComponent.vue';

export default {
  name: 'DevTipIconComponent',
  components: {
    DevTipItemContainerComponent,
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    getShowDevTipps() {
      return this.$store.getters.getShowDevTipps;
    },
    hasNew() {
      return this.$store.getters.getHasNewDevTipps;
    },
  },
  methods: {
    toggleShowDevTipps() {
      this.$store.commit('toggleShowDevTipps');
    },
  },
};
</script>

<style scoped>
.icon-container {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 30px;
  transition: all 0.3s ease-in-out;
}

.icon-container.hovered {
  transform: scale(1.1);
  background-color: rgba(128, 128, 128, 0.8);
  cursor: pointer;
}

.fa-lightbulb {
  font-size: 2.5rem;
  position: relative;
  z-index: 0;
}

.glowing {
  color: black;
}

.checked-icon {
  color: var(--color-orange);
  font-size: 40px;
}

.unchecked-icon {
  font-size: 40px;
}

.circle-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 20px;
  color: var(--color-orange);
  z-index: 1;
}
</style>
