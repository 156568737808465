import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEye,
  faEyeSlash,
  faArrowLeft,
  faCircleCheck,
  faCheck,
  faAngleDown,
  faPowerOff,
  faArrowRight,
  faCalendar,
  faClock,
  faCircleNotch,
  faCircleHalfStroke,
  faAngleRight,
  faAngleLeft,
  faCirclePlus,
  faPen,
  faBoxArchive,
  faUser,
  faTrashCan,
  faLeftLong,
  faRightLong,
  faPlus,
  faCopy,
  faCrown,
  faXmark,
  faCaretDown,
  faBan,
  faCircleQuestion,
  faSquareCheck,
  faSquare,
  faTriangleExclamation,
  faHandsClapping,
  faChevronDown,
  faChevronUp,
  faStar,
  faCircle,
  faThumbsUp,
  faThumbsDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import {
  faLightbulb,
  faSquare as farSquare,
  faClock as farClock,
  faHeart,
  faCircleCheck as farCircleCheck,
} from '@fortawesome/free-regular-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  install(app) {
    library.add(faEyeSlash);
    library.add(faEye);
    library.add(faArrowLeft);
    library.add(faCircleCheck);
    library.add(faCheck);
    library.add(faAngleDown);
    library.add(faPowerOff);
    library.add(faArrowRight);
    library.add(faCalendar);
    library.add(faClock);
    library.add(faCircleNotch);
    library.add(faCircleHalfStroke);
    library.add(faAngleRight);
    library.add(faAngleLeft);
    library.add(faCirclePlus);
    library.add(faPen);
    library.add(faBoxArchive);
    library.add(faUser);
    library.add(faTrashCan);
    library.add(faLeftLong);
    library.add(faRightLong);
    library.add(faPlus);
    library.add(faCopy);
    library.add(faCrown);
    library.add(faXmark);
    library.add(faCaretDown);
    library.add(faBan);
    library.add(faCircleQuestion);
    library.add(faSquareCheck);
    library.add(faSquare);
    library.add(faTriangleExclamation);
    library.add(faHandsClapping);
    library.add(faChevronDown);
    library.add(faChevronUp);
    library.add(faStar);
    library.add(faLightbulb);
    library.add(faCircle);
    library.add(farSquare);
    library.add(faThumbsUp);
    library.add(faThumbsDown);
    library.add(faAngleUp);
    library.add(farClock);
    library.add(faHeart);
    library.add(farCircleCheck);
    library.add(faMicrosoft);
    app.component('font-awesome-icon', FontAwesomeIcon);
  },
};
