<template>
  <div :class="getCardClass" @click.prevent="navigateToTask">
    <div class="headline">
      <h2>{{ type }}</h2>
      <div class="done">
        <font-awesome-icon :class="progressIconStyle" :icon="statusIcon"/>
      </div>
    </div>
    <div>
      <h4 v-if="task.name">{{ task.name ?? '' }}</h4>
      <p v-if="task.description">{{ task.description ?? '' }}</p>
    </div>
    <div class="status">
      <p class="text-muted">{{ $t(`TaskComponent.${status.dateHeadline}`) }}</p>
      <div class="time-rubber" :style="dateStyle">
        <font-awesome-icon class="icon" icon="fa-calendar"/>
        <p class="date">{{ status.dateToShow }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import AssessmentHelper from '@/helpers/AssessmentHelper';

export default {
  name: 'TaskComponent',
  props: ['task'],
  computed: {
    // eslint-disable-next-line consistent-return
    type() {
      let returnString = '';
      if (this.task.assessmentRepresentationProperties) {
        if (this.task.assessmentRepresentationProperties.assessmentName) {
          // eslint-disable-next-line max-len
          returnString = this.task.assessmentRepresentationProperties.assessmentName[this.$i18n.locale];
        }
      } else {
        // maps type to the right translation
        returnString = this.$t(`TaskComponent.${this.task.type}`);
      }
      return returnString;
    },
    status() {
      return AssessmentHelper.evaluateStatus(this.task);
    },
    getCardClass() {
      if (this.status.status === 'inactive') {
        return 'task-card disable-click';
      }
      return 'task-card';
    },
    progressIconStyle() {
      switch (this.task.status) {
        case 'began':
          return 'icon-style-began';
        case 'open':
        case 'expired':
          return 'icon-style-open-expired';
        case 'submitted':
          return 'icon-style-submitted';
        default:
          return 'icon-style-open-expired';
      }
    },
    statusIcon() {
      switch (this.task.status) {
        case 'began':
          return 'fa-circle-half-stroke';
        case 'open':
        case 'expired':
          return 'fa-circle-notch';
        case 'submitted':
          return 'fa-circle-check';
        default:
          return 'fa-circle-notch';
      }
    },
    dateStyle() {
      if (this.status.status === 'active') {
        return 'color: var(--color-orange);';
      }
      return 'background: var(--color-disabled);';
    },
  },
  methods: {
    // TODO disable links if submitted or expired
    navigateToTask() {
      if (this.task.type === 'kss') {
        this.$router.push(`/evaluation/questionnaire?id=${this.task._id}`);
      }

      if (this.task.type === 'oi_kpi') {
        this.$router.push(`/evaluation/questionnaire?id=${this.task._id}`);
      }

      if (this.task.type === 'custom') {
        this.$router.push(`/evaluation/questionnaire?id=${this.task._id}`);
      }

      if (this.task.type === 'mindset') {
        this.$router.push(`/oim-intro?id=${this.task._id}`);
      }
    },
  },
};
</script>

<style scoped>

.time-rubber { /* Date-container on task-element, change color to: var(--color-disable)*/
  display: flex;
  flex-direction: row;
  margin: 0.2rem 0;
  padding: 0.7rem;
  align-items: center;
  height: 2.2rem;
  width: max-content;
  border-radius: 4px;
  background-color: var(--color-orange);
}

.disable-click {
  pointer-events: none;
}

.time-rubber .icon {
  display: flex;
  padding: 3px;
  width: 2rem;
  color: var(--color-white);
}

.text-muted {
  color: var(--color-disabled);
}

.date {
  color: var(--color-white);
  padding: 5px;
  width: 100%;
}

.icon-style-open-expired {
  font-size: 2rem;
  color: var(--color-disabled)
}

.icon-style-began {
  font-size: 2rem;
  color: var(--color-yellow-orange);
}

.icon-style-submitted {
  font-size: 2rem;
  color: var(--color-orange);
}

</style>
