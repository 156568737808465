<template>
  <div class="popup-overview">
    <div class="popup-content">
      <h3>{{ $t('DevTipLibraryOverlayComponent.yourTips') }}</h3>
      <div class="overview-container">
        <dev-tip-item-component v-for="item in getDevTippLibrary"
                                :key="item"
                                :tip="item"
                                :show-text="true"
                                :overlay-id="this.data.id"/>
        <p v-if="getDevTippLibrary.length === 0">
          {{ $t('DevTipLibraryOverlayComponent.noTips') }}
        </p>
      </div>
    </div>
    <button class="close-btn" @click.prevent="closePopup">
      <font-awesome-icon icon="fa-xmark"/>
    </button>
  </div>
</template>

<script>
import DevTipItemComponent from '@/components/Home/Shared/DevTip/DevTipItemComponent.vue';

export default {
  name: 'DevTipLibraryOverlayComponent',
  props: {
    data: Object,
  },
  components: { DevTipItemComponent },
  computed: {
    getDevTippLibrary() {
      return this.$store.getters.getDevTippLibrary;
    },
  },
  methods: {
    closePopup() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
  },
  async mounted() {
    await this.$store.dispatch('getDevTippLibrary');
    // TODO route that accepts an array
    this.getDevTippLibrary
      .filter((el) => el.new)
      .map(async (el) => {
        await this.$store.dispatch('setNoticeDevTipp', { id: el.tip_id });
      });
  },
};
</script>

<style scoped>
.popup-overview {
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  padding: 1em;
  border-radius: 2em;
  line-height: 1.5em;
  box-shadow: 4px 5px 0 3px rgba(0, 0, 0, 0.1);
  width: 90%;
  height: 80%;
  position: relative;
  overflow-y: auto;
}

.popup-content {
  position: relative;
}

.close-btn {
  font-family: 'Poppins';
  position: absolute;
  top: 5px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.close-btn:hover {
  color: var(--color-orange);
}

.popup-overview h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media only screen and (min-width: 540px) {
  .popup-overview {
    font-size: 14px;
    /*right: 7%;
    max-width: 30%;*/
  }

  .button-grid {
    margin-right: 5em;
  }
}

@media only screen and (min-width: 1150px) {
  .popup-overview {
    font-size: 14px;
    /*right: 7%;*/
    max-width: 60%;
  }
}

</style>
