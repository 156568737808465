<template>
  <div class="task-card">
    <div @click.prevent="toogleDetails">
      <div class="headline">
        <h2>KSS Tracker: {{ createdTask?.name }}</h2>
        <div class="delete">
          <i>
            <font-awesome-icon icon="fa-pen"/>
          </i>
          <i class="icon" @click.stop="deleteTask">
            <font-awesome-icon icon="fa-trash-can"/>
          </i>
        </div>
      </div>
      <div class="status">
        <div class="time-rubber">
          <i class="icon">
            <font-awesome-icon icon="fa-calendar"/>
          </i>
          <p class="labelname">{{ formattedDate }}</p>
        </div>
      </div>
    </div>
    <div v-if="showContainer" class="expandable">
      <div class="content">
        <div class="forms">
          <vee-form @submit="updateKssTask">
            <div class="time-wrapper">
              <div class="time-box">
                <label for="cbox1" class="label" id="lbl_calDate"
                       @click.prevent="toggleShowCalendar">
                  <font-awesome-icon icon="fa-calendar"/>
                  {{ formattedDate ?? $t('KssRuntimeComponent.selectDate') }}
                </label>
                <div class="area" id="div_calendar-date" v-if="getShowCalendar">
                  <edit-kss-calendar-component v-if="createdTask" :initialDate="selectedDate"
                                               @updateDate="updateSelectedDate"/>
                </div>
                <label for="cbox2" class="label" id="lbl_cal-time"
                       @click.prevent="toggleShowRuntime">
                  <font-awesome-icon icon="fa-clock"/>
                  {{ formattedTime ?? $t('KssRuntimeComponent.selectRuntime') }}
                </label>
                <div class="area" id="div_calendar-time" v-if="getShowRuntime">
                  <!----------------- RUNTIME ----------------->
                  <div class="runtime" id="div_runtime">
                    <edit-kss-runtime-component v-if="createdTask"
                                                :initialDate="getRuntimeDate"
                                                :initialRecurring="createdTask.repeatRules[0]"
                                                @updateTime="updateSelectedTime"
                                                @updateReccuring="updateReccuring"/>
                  </div>
                </div>
              </div>
            </div>
            <edit-kss-form-component :initialSubject="createdTask.name"
                                     :initialDescription="createdTask.description"
                                     :involvedUsers="createdTask.involvedUsers"
                                     :allKssParticipants="allKssParticipants"
                                     :allInvolvedKssUsers="involvedKssUsers"
                                     :selectedIds="selectedUsers"
                                     :externalUsers="externalUsers"
                                     :KssTeams="KssTeams"
                                     @updateSubject="updateSubject"
                                     @updateDescription="updateDescription"
                                     @updateSelectedUserIds="updateSelectedUsers"
                                     @updateExternalUsers="updateExternalUsers"/>
          </vee-form>
        </div>
        <div class="btns">
          <button type="button" class="btn-update" name="update"
                  @click.prevent="updateKssTask">
            {{ $t('KssTaskCreatedComponent.updateTask') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <pop-up-confirmation-component v-if="showPopup"
                                 :message="popupMessage"
                                 :confirmButtonText="deleteButtonText"
                                 :cancelButtonText="cancelButtonText"
                                 @cancel="onPopupCancel"
                                 @confirm="onPopupConfirm"/>
</template>

<script>
import PopUpConfirmationComponent from '@/components/global/PopUpConfirmationComponent.vue';
import EditKssCalendarComponent
  from '@/components/Home/KssCreator/KssEditComponents/EditKssCalendarComponent.vue';
import EditKssRuntimeComponent
  from '@/components/Home/KssCreator/KssEditComponents/EditKssRuntimeComponent.vue';
import EditKssFormComponent
  from '@/components/Home/KssCreator/KssEditComponents/EditKssFormComponent.vue';

export default {
  name: 'KssTaskCreatedComponent',
  components: {
    EditKssFormComponent,
    EditKssRuntimeComponent,
    EditKssCalendarComponent,
    PopUpConfirmationComponent,
  },
  props: ['createdTask', 'allKssParticipants', 'involvedKssUsers'],
  data() {
    return {
      showPopup: false,
      showContainer: false,
      showRuntime: false,
      showCalendar: false,
      selectedHours: null,
      selectedMinutes: null,
      selectedDate: null,
      repeatRules: this.createdTask.repeatRules,
      reccurringObject: null,
      selectedUsers: this.createdTask.involvedUsers,
      meetingSubject: this.createdTask.name,
      meetingDescription: this.createdTask.description,
      KssTeams: this.$store.getters.getKssTeams,
      externalUsers: [],
      popupMessage: this.$t('KssTaskCreatedComponent.popup.deleteMessage'),
      deleteButtonText: this.$t('KssTaskCreatedComponent.popup.deleteButton'),
      cancelButtonText: this.$t('KssTaskCreatedComponent.popup.cancelButton'),
    };
  },
  computed: {
    formattedDate() {
      if (!this.selectedDate) {
        return '';
      }
      return `${(`0${this.selectedDate.getDate()}`).slice(-2)}.${(`0${this.selectedDate.getMonth() + 1}`).slice(-2)}.${this.selectedDate.getFullYear()}`;
    },
    formattedTime() {
      if (!this.selectedHours && !this.selectedHours) {
        return '';
      }
      return `${(`0${this.selectedHours}`).slice(-2)}:${(`0${this.selectedMinutes}`).slice(-2)}`;
    },
    getRuntimeDate() {
      return new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        this.selectedDate.getDate(),
        this.selectedHours,
        this.selectedMinutes,
      );
    },
    getShowCalendar() {
      return this.showCalendar;
    },
    getShowRuntime() {
      return this.showRuntime;
    },
  },
  methods: {
    updateSelectedDate(date) {
      this.selectedDate = date;
    },
    updateSelectedUsers(users) {
      this.selectedUsers = users;
    },
    updateSelectedTime(time) {
      const hours = time.split(':')[0];
      const minutes = time.split(':')[1];
      this.selectedHours = Number(hours);
      this.selectedMinutes = Number(minutes);
    },
    updateReccuring(reccuringObject) {
      this.reccurringObject = reccuringObject;
    },
    updateSubject(subject) {
      this.meetingSubject = subject;
    },
    updateDescription(description) {
      this.meetingDescription = description;
    },
    updateExternalUsers(externalUsers) {
      this.externalUsers = externalUsers;
    },
    toogleDetails() {
      this.showContainer = !this.showContainer;
    },
    toggleShowCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    toggleShowRuntime() {
      this.showRuntime = !this.showRuntime;
    },
    deleteTask() {
      this.showPopup = true;
    },
    onPopupCancel() {
      this.showPopup = false;
    },
    checkForErrors() {
      let hasError = false;

      if (!this.selectedDate) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.kss.selectDateErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (!this.selectedHours) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.kss.selectTimeErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (this.selectedDate
        && this.selectedHours
        && !this.checkDateAndTimeValid()) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.kss.pastDateErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (!this.meetingSubject) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.kss.selectSubjectErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (this.selectedUsers.length === 0
        && this.externalUsers.length === 0) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.kss.selectParticipantsErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (this.reccurringObject !== null) {
        if (this.reccurringObject.isReccuring) {
          if (this.reccurringObject.frequency === 'WEEKLY') {
            if (this.reccurringObject.byDayOfWeek.length === 0) {
              this.$store.commit('setToastNotification', {
                id: Math.random(),
                message: this.$t('ToastComponenet.kss.selectRecurringDays'),
                type: 'error',
              });
              hasError = true;
            }
          }
        }
      }

      return hasError;
    },
    checkDateAndTimeValid() {
      const selectedDate = new Date(this.selectedDate);
      selectedDate.setHours(this.selectedHours, this.selectedMinutes);

      const currentTime = new Date();
      const timeDifference = currentTime - selectedDate;

      return timeDifference <= 60 * 60 * 1000;
    },
    buildRepeatRules(dateTaskBegin) {
      if (this.reccurringObject === null) {
        this.repeatRules[0].start = dateTaskBegin;

        if (Object.prototype.hasOwnProperty.call(this.repeatRules[0], 'end')) {
          this.repeatRules[0].end = dateTaskBegin;
        }
        return this.repeatRules;
      }

      if (!this.reccurringObject.isReccuring) {
        return [{
          frequency: 'MINUTELY',
          start: dateTaskBegin,
          end: dateTaskBegin,
        }];
      }

      const tempReccuringObj = { ...this.reccurringObject };
      delete tempReccuringObj.isReccuring;

      return [{
        ...tempReccuringObj,
        start: dateTaskBegin,
      }];
    },
    async updateKssTask() {
      try {
        if (this.checkForErrors()) {
          return;
        }

        const dateTaskBegin = new Date(
          this.selectedDate.getFullYear(),
          this.selectedDate.getMonth(),
          this.selectedDate.getDate(),
          this.selectedHours,
          this.selectedMinutes,
        );

        const repeatRules = this.buildRepeatRules(dateTaskBegin.toUTCString());

        // this is needed to filter out the external users from the selected ids
        const externalIds = this.involvedKssUsers.filter((el) => el.userStatus === 'external')
          .map((el) => el._id);

        const data = {
          id: this.createdTask._id,
          name: this.meetingSubject,
          description: this.meetingDescription,
          involvedUsers: this.selectedUsers.filter((el) => !externalIds.includes(el)),
          involvedExternalUsers: this.externalUsers,
          repeatRules,
        };

        await this.$store.dispatch('updateKssTask', data);
        this.showContainer = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.kss.kssUpdated'),
          type: 'success',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    async onPopupConfirm() {
      const data = {
        taskID: this.createdTask._id,
      };
      try {
        await this.$store.dispatch('deleteKssTask', data);
        this.showPopup = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('KssTaskCreatedComponent.toast.successfulDelete'),
          type: 'success',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('KssTaskCreatedComponent.toast.errorDelete'),
          type: 'error',
        });
      }
    },
  },
  mounted() {
    this.involvedKssUsers.forEach((el) => {
      if (el.userStatus === 'external' && this.createdTask.involvedUsers.includes(el._id)) {
        this.externalUsers.push(el.email);
      }
    });
    this.selectedHours = new Date(this.createdTask.repeatRules[0].start).getHours();
    this.selectedMinutes = new Date(this.createdTask.repeatRules[0].start).getMinutes();
    this.selectedDate = new Date(this.createdTask.repeatRules[0].start);
  },
};
</script>

<style scoped>

.task-card {
  background: var(--color-light);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
}

.task-card:hover {
  box-shadow: 0 4px 20px 0 #aaa;
  cursor: pointer;
}

.task-card .headline {
  display: flex;
  justify-content: space-between;
}

.task-card .headline h2 {
  font-size: 23px;
}

.task-card .headline .delete i {
  padding-left: 10px;
  font-size: 1.3rem;
  color: var(--color-disabled);
}

.task-card .headline .delete i:hover {
  color: var(--color-orange);
}

.time-rubber {
  display: flex;
  flex-direction: row;
  margin: 0.2rem 0;
  padding: 0.7rem;
  align-items: center;
  height: 2.2rem;
  width: min-content;
  border-radius: 4px;
  background-color: var(--color-orange);
}

.time-rubber .icon {
  display: flex;
  padding: 3px;
  width: 2rem;
  color: var(--color-white);
}

.labelname {
  color: var(--color-white);
  padding: 3px;
}

.forms {
  /* max-width: 90vw; */
  /* width: 600px; */
  /*height: auto;*/
  display: block;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 30px;
  background: var(--color-milk);
}

.forms .time-wrapper {
  /*min-height: 100vh;*/
  box-sizing: border-box;
  padding: 0px;
  max-width: 960px;
  margin: 0 auto;
}

.time-wrapper {
  /*min-height: 100vh;*/
  box-sizing: border-box;
  padding: 0px;
  max-width: 960px;
  margin: 0 auto;
}

.time-wrapper .time-box {
  display: flex;
  flex-wrap: wrap;
}

.time-box .label {
  text-align: center;
  display: block;
  width: 350px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: var(--color-orange);
  background-color: var(--color-white);
  cursor: pointer;
}

.time-box .area {
  order: 99;
  flex-grow: 1;
  width: 100%;
}

.runtime {
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: var(--color-milk);
}

.expandable {
  z-index: 20;
  cursor: default;
}

/*------------------ BUTTONS -------------------*/
.btns {
  margin-top: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.btn-update {
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-update:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

</style>
