<template>
  <div class="task-card">
    <div class="headline">
      <h2>KSS Tracker: {{ archivedTask?.name }}</h2>
      <div class="archived">
        Archived
        <i class="icon">
          <font-awesome-icon icon="fa-box-archive"/>
        </i>
      </div>
    </div>
    <div class="status">
      <div class="time-rubber">
        <i class="icon">
          <font-awesome-icon icon="fa-calendar"/>
        </i>
        <p class="labelname">{{ formattedDate }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KssTaskArchivedComponent',
  props: ['archivedTask'],
  computed: {
    formattedDate() {
      const date = new Date(this.archivedTask.date_created);
      return `${(`0${date.getDate()}`).slice(-2)}.${(`0${date.getMonth() + 1}`).slice(-2)}.${date.getFullYear()}`;
    },
  },
};
</script>

<style scoped>

.task-card {
  background: var(--color-light);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
}

.task-card:hover {
  box-shadow: 0 4px 20px 0 #aaa;
  cursor: pointer;
}

.task-card .headline {
  display: flex;
  justify-content: space-between;
}

.task-card .headline h2 {
  font-size: 23px;
}

.task-card .headline .edit i {
  font-size: 1rem;
  color: var(--color-disabled);
}

.time-rubber {
  display: flex;
  flex-direction: row;
  margin: 0.2rem 0;
  padding: 0.7rem;
  align-items: center;
  height: 2.2rem;
  width: min-content;
  border-radius: 4px;
  background-color: var(--color-orange);
}

.time-rubber .icon {
  display: flex;
  padding: 3px;
  width: 2rem;
  color: var(--color-white);
}

.labelname {
  color: var(--color-white);
  padding: 3px;
}

.task-card .headline .archived {
  color: var(--color-orange);
}

.task-card .headline .archived i {
  padding-left: 5px;
  font-size: 1rem;
  color: var(--color-orange);
}

</style>
