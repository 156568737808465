<template>
  <div class="links-container">
    <a v-bind:href = "url" target="_blank">
      <img v-bind:src = "icon" alt="icon" class="link-logo" id="link_logo">
    </a>
  </div>
</template>

<script>
export default {
  name: 'QuickAccessLinksComponent',
  props: {
    url: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

  <style scoped>

  .links-container {
    width: 20px;
    max-width: 30px;
    min-width: 20px;
    height: 100px;
    border-radius: 10px;
    color: white;
    font-family: Arial, sans-serif;
    flex: 1;
    margin-left: -25px;
  }
  .link-logo{
    height: 4rem;
    width: 4rem;
    border-radius: 25%;
    overflow: hidden;
  }

  @media (min-width: 521px) and (max-width: 1919px){
  .links-container {
    margin-left: -15px;
  }
  .link-logo{
    height: 3rem;
    width: 3rem;
    border-radius: 15%;
  }
  }

  @media (max-width: 520px) {
    .links-container {
    width: 25% !important;
    max-width: 30%;
    min-width: 10%;
    height: 100px;
    margin-left: 0rem !important;
  }
  }
  </style>
