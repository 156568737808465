<template>
  <div class="questionnaire-view">
    <header>
      <div class="inner-header">
        <div class="logo-container">
          <router-link to="/">
            <img src="@/assets/logo_transparent.png" alt="" class="platform-logo" id="img_logo">
          </router-link>
        </div>
      </div>
    </header>
    <main>
      <router-view/>
    </main>
    <footer>
      <footer-component/>
    </footer>
  </div>
</template>

<script>
import FooterComponent from '@/components/Home/Layout/FooterComponent.vue';

export default {
  name: 'QuestionnaireView',
  components: { FooterComponent },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.questionnaire-view {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
  color: var(--color-black);
  background-attachment: fixed;
  background-repeat: repeat;
  background-position: center;
  overflow-x: hidden;
  background-color: var(--color-orange);
}

/*---------------- HEADER ------------------*/
.questionnaire-view header {
  width: 100%;
  top: 0;
  padding: 0.7rem;
  z-index: 10;
}

.questionnaire-view header .inner-header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.questionnaire-view .logo-container {
  height: 100%;
  display: table;
  float: left;
}

.questionnaire-view .logo-container .platform-logo {
  width: 106px;
  height: auto;
  padding-top: 5px;
  display: table-cell;
  vertical-align: center;
}

/*---------------- GENERAL ------------------*/

.questionnaire-view h1 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
}

.questionnaire-view h2 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
}

.questionnaire-view h3 {
  font-family: "Poppins";
  font-size: 23px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
}

.questionnaire-view h4 {
  padding: 0.5rem 0;
}

.questionnaire-view p {
  padding: 0.5rem 0;
}

.questionnaire-view .link {
  color: var(--color-orange);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 19px;
  text-align: right;
  text-decoration: none;
}

.questionnaire-view .link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

.questionnaire-view .photo { /*gilt für alle Fotos*/
  display: block;
  width: 100%;
}

/*------------ FOOTER ---------------*/
.questionnaire-view footer {
  color: var(--color-white);
  background-color: var(--color-black);
}

.questionnaire-view .inner-footer {
  margin: 0 auto;
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.questionnaire-view .inner-footer > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 300px;
}

.questionnaire-view .left {
  padding: 1rem 0;
  text-align: left;
}

.questionnaire-view .left a {
  color: var(--color-white);
  font-weight: 400;
}

.questionnaire-view .left a:hover {
  color: var(--color-orange);
}

.questionnaire-view .left #btn_legal-notice {
  margin-right: 3rem;
}

.questionnaire-view .right {
  padding: 1rem 0;
  text-align: right;
}

.questionnaire-view .right a {
  color: var(--color-white);
  font-weight: 400;
}

.questionnaire-view .right a:hover {
  color: var(--color-orange);
}

/*==================== MEDIA QUERIES =====================*/
@media screen and (max-width: 749px) {
  .left {
    text-align: center;
  }

  .right {
    text-align: center;
  }
}

@media (max-width: 520px) {
  .breadcrumbs {
    margin: -1rem 0;
  }

  .chapter {
    margin: 1rem 0;
  }

  .title {
    margin: 1rem 0;
  }
}

@media (min-width: 521px) and (max-width: 768px) {
  .breadcrumbs {
    margin: -1rem -2rem;
  }

  .chapter {
    margin: 1rem -2rem;
  }

  .title {
    margin: 1rem -2rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .breadcrumbs {
    margin: 0 -3rem;
  }

  .chapter {
    margin: 0 -3rem 1rem;
  }

  .title {
    margin: 0 -3rem 1rem;
  }

}

@media (min-width: 1920px) {
  .breadcrumbs {
    margin: 0 -10rem -1rem;
  }

  .chapter {
    margin: 0rem -10rem 1rem;
  }

  .title {
    margin: 0rem -10rem 1rem;
  }

}

body {
  background-color: var(--color-orange);
}

header {
  background: var(--color-white);
}

/*------------------ BREADCRUMBS -------------------*/
.breadcrumbs {
  margin: 0.4rem -10rem 0.5rem;
  padding: 10px 0;
  font-family: sans-serif;
}

.breadcrumbs-item {
  display: inline-block;
}

.breadcrumbs-item:not(:last-of-type)::after {
  content: '|';
  margin: 0 5px;
  color: var(--color-white);
}

.breadcrumbs-link {
  text-decoration: none;
  color: var(--color-white);
  font-weight: 300;
}

.breadcrumbs-link:hover {
  color: var(--color-disabled);
}

.breadcrumbs-link-active {
  color: var(--color-white);
  font-weight: 400;
}

/*------------------ CHAPTER -------------------*/
.chapter {
  margin: 0 -10rem 1rem;
  padding: 10px 0;
}

.chapter h1 {
  color: var(--color-white);
}
</style>
