<template>
  <div class="popup-feedback">
    <button class="close-btn" @click="closePopup">
      <font-awesome-icon icon="fa-xmark"/>
    </button>
    <div class="feedback-grid">
      <font-awesome-icon class="lightbulb-icon" icon="fa-regular fa-lightbulb"/>
      <div>
        <p><b>{{ $t('FeedbackOverlayComponent.devTipFeedback') }}</b></p>
      </div>
    </div>
    <div class="container">
      <p>
        <b>{{ $t('FeedbackOverlayComponent.ourTip') }}</b>
        {{ data.notification?.content?.text?.[getLocale] ?? '-' }}</p>
    </div>
    <p><b>{{ $t('FeedbackOverlayComponent.yourFeedback') }}</b></p>
    <div class="container">
      <form action="#">
        <div class="feedback-grid2">
          <p>{{ $t('FeedbackOverlayComponent.wasTipHelpful') }}</p>
          <div class="feedback-grid2" id="feedback-grid2">
            <button type="button" class="grid-button"
                    :class="{ 'grid-button-selected': getHelpful === true }"
                    @click.prevent="helpful = true">
              <font-awesome-icon icon="fa-solid fa-thumbs-up"/>
              {{ $t('FeedbackOverlayComponent.yesSuper') }}
            </button>
            <button type="button" class="grid-button"
                    :class="{ 'grid-button-selected': getHelpful === false }"
                    @click.prevent="helpful = false">
              <font-awesome-icon icon="fa-solid fa-thumbs-down"/>
              {{ $t('FeedbackOverlayComponent.notReally') }}
            </button>
          </div>
        </div>
        <textarea v-model="text"
                  :placeholder="$t('FeedbackOverlayComponent.placeholderText')"/>
        <div class="feedback-grid3">
          <button type="button" class="grid-button" :disabled="buttonDisabled"
                  @click.prevent="submitFeedback">
            {{ $t('FeedbackOverlayComponent.sendFeedback') }}
          </button>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
export default {
  name: 'FeedbackOverlayComponent',
  props: {
    data: Object,
  },
  data() {
    return {
      text: '',
      helpful: this.data.notification.content.helpful || null,
      buttonDisabled: false,
    };
  },
  computed: {
    getHelpful() {
      return this.helpful;
    },
    getLocale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async submitFeedback() {
      try {
        if (!this.inputCheck()) {
          return;
        }
        this.buttonDisabled = true;
        await this.$store.dispatch('submitFeedback', {
          id: this.data.notification?.content?.tipId,
          feedback: {
            positive: this.helpful,
            feedback_text: this.text,
          },
        });
        this.buttonDisabled = false;
        this.$store.commit('removeFrontendOverlay', this.data.id);
      } catch (error) {
        this.buttonDisabled = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('FeedbackOverlayComponent.errorCouldNotFeedback'),
          type: 'error',
        });
      }
    },
    inputCheck() {
      if (this.helpful !== null) {
        return true;
      }
      this.$store.commit('setToastNotification', {
        id: Math.random(),
        message: this.$t('FeedbackOverlayComponent.errorRequired'),
        type: 'error',
      });
      return false;
    },
    closePopup() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
  },
};
</script>

<style scoped>
.popup-feedback {
  color: white;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Add transform to center the popup */
  margin-right: 5%;
  background-color: rgb(0, 0, 0);
  padding: 1em 1em 1em 3em;
  border-radius: 2em;
  line-height: 1.5em;
}

.popup-feedback .container {
  padding-left: 3em;
}

.button-grid {
  margin-right: 0em;
}

.lightbulb-icon {
  font-size: 32px;
  color: #fdd835;
  text-shadow: 0 0 10px #fdd835;
}

.feedback-grid2 button.grid-button,
.feedback-grid3 button.grid-button {
  cursor: pointer;
  text-decoration: none;
  color: #1B1B1B;                               /*color: white;*/
  background: white none;                       /*background: #F49718 none;*/
  border: 1px solid var(--color-orange);        /*border: 1px solid #1E2019;*/
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.feedback-grid2 button.grid-button > svg,
.feedback-grid3 button.grid-button > svg {
  margin-right: 8px;
}

.feedback-grid2 button.grid-button:hover,
.feedback-grid3 button.grid-button:hover {
  text-decoration: none;
  color: white;                                  /*color: #1B1B1B;*/
  background-color: #F49718;                      /*background-color: white;*/
  border: 1px solid #1E2019;                     /*border: 1px solid var(--color-orange);*/
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
}

.feedback-grid2 button.grid-button:focus,
.feedback-grid3 button.grid-button:focus {
  outline: none;
}

.grid-button-selected {
  color: white !important;                         /*color: #1B1B1B !important;*/
  background-color: #F49718 !important;             /*background-color: white !important;*/
  border: 1px solid #1E2019 !important;            /*border: 1px solid #F49718 !important;*/
}

@media only screen and (min-width: 540px) {
  .popup-feedback {
    font-size: 16px;
    left: 50%;
    width: 60%;
    max-width: 90vh;
  }

  .grid-button {
    width: auto;
  }
}

.feedback-grid {
  display: grid;
  margin-left: -2em;
  grid-template-columns: 50px 1fr;
  place-items: center;
  justify-items: center;
}

.feedback-grid div {
  text-align: left;
  width: 100%;
  line-height: .7em;
}

.feedback-grid2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5em;
  place-items: center;
  justify-items: center;
  padding: 0 3em 1.5em 3em;
}

@media only screen and (min-width: 1080px) {
  .feedback-grid2 {
    display: flex; /* Change from grid to flex */
    justify-content: space-between; /* Add space between the two columns */
    align-items: center; /* Vertically align items */
    padding: 0 3em 1.5em 3em;
  }
}

#feedback-grid2 {
  padding: 0;
}

.feedback-grid2 .grid-button {
  text-decoration: none;
  color: #1B1B1B;                               /*color: white;*/
  background: white none;                       /*background: #F49718 none;*/
  border: 1px solid var(--color-orange);        /*border: 1px solid #1E2019;*/
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
  display: grid;
  grid-template-columns: 40px 1fr;
  place-items: center;
  justify-items: left;
}

.feedback-grid2 .grid-button:hover, .feedback-grid3 .grid-button:hover {
  text-decoration: none;
  color: white;                                  /*color: #1B1B1B;*/
  background-color: #F49718;                      /*background-color: white;*/
  border: 1px solid #1E2019;                     /*border: 1px solid var(--color-orange);*/
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
}

#grid-button2 img {
  rotate: 180deg;
}

.feedback-grid3 {
  display: grid;
  place-items: center;
  justify-items: right;
}

.feedback-grid3 .grid-button {
  text-decoration: none;
  color: #1B1B1B;                               /*color: #1B1B1B;*/
  background: white none;                       /*background: #F49718;*/
  border: 1px solid var(--color-orange);        /*border: 1px solid #1B1B1B;*/
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
  display: inline-flex;
  align-items: center;
  width: auto;
  max-width: 150px;
}

.popup-feedback form textarea {
  width: 90%;
  height: 6.5em;
  border: none;
  outline: none;
  color: #000;
  font-size: 16px;
  margin-bottom: 1em;
  margin-top: -.5em;
  border-radius: 2em;
  padding: 1em;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 12px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  color: var(--color-milk);
}

.close-btn:hover {
  color: var(--color-orange);
}

</style>
