import NetworkAssessment from '@/helpers/networking/NetworkAssessment';

export default {
  state: {
    assessment: null,
    selectedQuestion: null,
    selectedQuestionType: null,
    questions: [],
    progress: null,
    forceInput: false,
    multipleChoose: 0,
    questionnaireIndex: 0,
    involvedUsers: [],
    progressText: null,
    chapterText: null,
    assesmentType: null,
    answer: null,
    locale: 'en',
    icQuestions: [],
    selectedIcQuestion: null,
    icQuestionnaireIndex: 0,
    icTextQuestionWeight: null,
    icForceInput: false,
    selectedIcQuestionType: null,
    isDone: false,
    isExpired: false,
    currentIcAnswerEntry: null,
    showNextButton: true,
  },
  getters: {
    getProgress: (state) => ((state.questionnaireIndex + 1) * 100) / (state.questions.length),
    getSelectedQuestion: (state) => state.selectedQuestion,
    getSelectedQuestionType: (state) => state.selectedQuestionType,
    getSelectedQuestionProgressText: (state) => state.progressText,
    getSelectedQuestionChapterText: (state) => state.chapterText,
    getQuestionnaireIndex: (state) => state.questionnaireIndex,
    getInvolvedUsers: (state) => state.involvedUsers,
    getSelectedIcQuestionType: (state) => state.selectedIcQuestionType,
    getIcQuestionnaireIndex: (state) => state.icQuestionnaireIndex,
    getForceInput: (state) => state.forceInput,
    getIcForceInput: (state) => state.icForceInput,
    getShowNextButton: (state) => state.showNextButton,
  },
  mutations: {
    setShowNextButton: (state, data) => {
      state.showNextButton = data;
    },
    setAssessment: (state, data) => {
      state.assessment = data;
    },
    setCurrentIcAnswerEntry: (state, data) => {
      state.currentIcAnswerEntry = data;
    },
    setQuestionnaireIndex: (state, data) => {
      state.questionnaireIndex = data;
      if (data < 0) {
        state.questionnaireIndex = 0;
      }
      if (data >= state.questions.length) {
        state.questionnaireIndex = state.questions.length - 1;
      }
    },
    setQuestions: (state, data) => {
      state.questions = data;
    },
    setProgress: (state, data) => {
      state.progress = data;
    },
    setForceInput: (state) => {
      state.forceInput = state.selectedQuestion.properties.options.forceInput;
    },
    setMultipleChoose: (state) => {
      state.multipleChoose = state.selectedQuestion.properties.options.multipleChoose;
    },
    selectInvolvedUser: (state, index) => {
      const selectedUsers = state.involvedUsers.filter((el) => el.isChecked)
        .map((el) => el._id);

      if (selectedUsers.length < state.multipleChoose) {
        state.involvedUsers[index].isChecked = !state.involvedUsers[index].isChecked;
      } else if (state.involvedUsers[index].isChecked) {
        state.involvedUsers[index].isChecked = false;
      }

      state.answer = state.involvedUsers.filter((el) => el.isChecked)
        .map((el) => el._id);
    },
    setInvolvedUsers: (state) => {
      if (state.selectedQuestionType !== 'KssChampionUserListQuestion') {
        state.involvedUsers = [];
        return;
      }

      state.selectedQuestion.properties.data.involvedUsers.forEach(
        (el) => {
          let isChecked = false;
          if (Array.isArray(state.answer)) {
            isChecked = state.answer.includes(el._id);
          }
          state.involvedUsers.push({
            ...el,
            isChecked,
          });
        },
      );
    },
    setSelectedQuestion: (state) => {
      state.selectedQuestion = state.questions[state.questionnaireIndex];
    },
    setSelectedQuestionType: (state) => {
      switch (state.selectedQuestion.type) {
        case 'integrativeComplexity':
          state.selectedQuestionType = 'IntegrativeComplexityQuestion';
          break;
        case 'rangeSlider':
          state.selectedQuestionType = 'SliderQuestion';
          break;
        case 'chooseFromList':
          state.selectedQuestionType = 'KssChampionUserListQuestion';
          break;
        default:
          state.selectedQuestionType = null;
          break;
      }
    },
    setSelectedQuestionProgressText: (state) => {
      state.progressText = state.selectedQuestion
        .properties.content.text_questionProgress[state.locale];
    },
    setSelectedQuestionChapterText: (state) => {
      state.chapterText = state.selectedQuestion
        .properties.content.text_chapter[state.locale];
    },
    setAnswer: (state, value) => {
      state.answer = value;
    },
    initAnswer: (state) => {
      if (state.selectedQuestion.properties.data !== undefined) {
        state.answer = state.selectedQuestion.properties.data.value;
      } else {
        state.answer = null;
      }
    },
    setLocale: (state, locale) => {
      state.locale = locale;
    },
    setIcTextQuestionWeight: (state) => {
      state.icTextQuestionWeight = state.selectedQuestion.properties.content.text_question_weight;
    },
    setSelectedIcQuestion: (state) => {
      state.selectedIcQuestion = state.icQuestions[state.icQuestionnaireIndex];
    },
    setSelectedIcQuestionType: (state) => {
      state.selectedIcQuestionType = state.selectedIcQuestion.type;
    },
    setIcQuestionnaireIndex: (state, data) => {
      state.icQuestionnaireIndex = data;
    },
    setIcAnswer: (state, value) => {
      state.selectedIcQuestion.data.value = value;
    },
    setInitIcQuestions: (state) => {
      state.icQuestions = [{
        properties: {
          dimensionName: 'integrativeComplexity',
          dimension: 'x',
          content: {
            text_chapter: {
              en: state.selectedQuestion.properties.content.text_chapter.en,
              de: state.selectedQuestion.properties.content.text_chapter.de,
            },
            text_questionProgress: {
              en: 'Question 1 of 2',
              de: 'Frage 1 von 2',
            },
            text_question: {
              en: state.selectedQuestion.properties.content.text_question_page1.en,
              de: state.selectedQuestion.properties.content.text_question_page1.de,
            },
          },
          data: {
            integrativeComplexityValues: state.selectedQuestion
              .properties.data.integrativeComplexityValues.filter((el) => el.positive),
          },
        },
        positive: true,
        class: 'question_element',
        type: 'AddIntegrativeComplexityValues',
      }, {
        properties: {
          dimensionName: 'integrativeComplexity',
          dimension: 'x',
          content: {
            text_chapter: {
              en: '', // TODO
              de: '', // TODO
            },
            text_questionProgress: {
              en: 'Question 2 of 2',
              de: 'Frage 2 von 2',
            },
            text_question: {
              en: state.selectedQuestion.properties.content.text_question_page2.en,
              de: state.selectedQuestion.properties.content.text_question_page2.de,
            },
          },
          data: {
            integrativeComplexityValues: state.selectedQuestion
              .properties.data.integrativeComplexityValues.filter((el) => !el.positive),
          },
        },
        positive: false,
        class: 'question_element',
        type: 'AddIntegrativeComplexityValues',
      }];
    },
    addRateIcQuestion: (state, value) => {
      state.icQuestions.push({
        properties: {
          dimensionName: 'integrativeComplexity',
          dimension: 'x',
          content: {
            text_chapter: {
              en: '',
              de: '',
            },
            text_questionProgress: {
              en: 'Question 2 of 2',
              de: 'Frage 2 von 2',
            },
            text_question: {
              en: state.icTextQuestionWeight.en,
              de: state.icTextQuestionWeight.de,
            },
          },
        },
        data: value,
        class: 'question_element',
        type: 'RateIntegrativeComplexityValue',
      });
    },
    setIcForceInput: (state, data) => {
      state.icForceInput = data;
    },
    setIsDone: (state, value) => {
      state.isDone = value;
    },
    setIsExpired: (state, value) => {
      state.isExpired = value;
    },
    removeQuestionType: (state) => {
      state.selectedQuestionType = null;
    },
    removeInvolvedUsers: (state) => {
      state.involvedUsers = [];
    },
    removeSelectedIcQuestionType: (state) => {
      state.selectedIcQuestionType = null;
    },
  },
  actions: {
    async getAssessment({
      commit,
      state,
    }, {
      id,
      locale,
    }) {
      const { data } = await NetworkAssessment.getAssessment(id);
      commit('setLocale', locale);
      commit('setAssessment', data.assessment);
      commit('setProgress', data.assessment.progress);
      commit('setQuestions', data.assessment.assessmentQuestionArray);
      commit('setQuestionnaireIndex', data.assessment.saved_until_question);
      commit('setSelectedQuestion');
      commit('setForceInput');
      commit('setMultipleChoose');
      commit('initAnswer');
      commit('setSelectedQuestionType');
      if (state.selectedQuestionType === 'IntegrativeComplexityQuestion') {
        commit('setIcTextQuestionWeight');
        commit('setInitIcQuestions');
        commit('setSelectedIcQuestion');
        commit('setSelectedIcQuestionType');
      } else {
        commit('removeSelectedIcQuestionType');
      }
      commit('setInvolvedUsers');
      commit('setSelectedQuestionProgressText');
      commit('setSelectedQuestionChapterText');
    },
    async setNextQuestion({
      state,
      commit,
      getters,
    }) {
      if (['AddIntegrativeComplexityValues', 'RateIntegrativeComplexityValue'].includes(state.selectedIcQuestionType)) {
        if (state.currentIcAnswerEntry !== null && state.currentIcAnswerEntry !== '') {
          const answer = {
            class: 'integrativeComplexityValue',
            positive: state.selectedIcQuestion.positive,
            text: state.currentIcAnswerEntry,
          };
          commit('addRateIcQuestion', answer);
        }
        commit('setCurrentIcAnswerEntry', null);

        const previousIndex = state.icQuestionnaireIndex;
        if (state.icQuestionnaireIndex + 1 < state.icQuestions.length) {
          state.icQuestionnaireIndex += 1;
        }
        commit('setIcQuestionnaireIndex', state.icQuestionnaireIndex);
        commit('setSelectedIcQuestion');
        commit('setSelectedIcQuestionType');

        if (state.icQuestionnaireIndex === previousIndex) {
          state.selectedQuestion.properties.data.integrativeComplexityValues = state.icQuestions
            .map((el) => el.data)
            .filter((e) => e);
        } else {
          return;
        }
      } else {
        if (state.selectedQuestion.properties.options.forceInput) {
          if (!state.answer) {
            return;
          }
        }

        // Set answer to questionnaire
        if (state.selectedQuestion.properties.data !== undefined) {
          state.selectedQuestion.properties.data.value = state.answer;
        } else {
          state.selectedQuestion.properties.data = { value: state.answer };
        }
      }

      if (state.assessment.saved_until_question < state.questionnaireIndex) {
        state.assessment.saved_until_question = state.questionnaireIndex + 1;
      }

      state.assessment.progress = getters.getProgress;

      if (state.questionnaireIndex + 1 === state.questions.length) {
        await NetworkAssessment.submitAssessment(state.assessment);
        commit('setIsDone', true);
        return;
      }
      await NetworkAssessment.updateAssessment(state.assessment);

      if (state.questionnaireIndex + 1 < state.questions.length) {
        state.questionnaireIndex += 1;
      }
      commit('setQuestionnaireIndex', state.questionnaireIndex);
      commit('setSelectedQuestion');
      commit('setSelectedQuestionType');
      commit('setForceInput');
      commit('setMultipleChoose');
      if (state.selectedQuestionType === 'IntegrativeComplexityQuestion') {
        commit('setIcQuestionnaireIndex', 0);
        commit('setIcTextQuestionWeight');
        commit('setInitIcQuestions');
        commit('setSelectedIcQuestion');
        commit('setSelectedIcQuestionType');
      } else {
        commit('removeSelectedIcQuestionType');
      }
      commit('initAnswer');
      commit('setInvolvedUsers');
      commit('setSelectedQuestionProgressText');
      commit('setSelectedQuestionChapterText');
    },
    setPreviousQuestion({
      state,
      commit,
    }) {
      if (['AddIntegrativeComplexityValues', 'RateIntegrativeComplexityValue'].includes(state.selectedIcQuestionType)) {
        const previousIndex = state.icQuestionnaireIndex;
        if (state.icQuestionnaireIndex >= 1) {
          state.icQuestionnaireIndex -= 1;
        }
        commit('setIcQuestionnaireIndex', state.icQuestionnaireIndex);
        commit('setSelectedIcQuestion');
        commit('setSelectedIcQuestionType');

        // State index 0 to previous
        if (previousIndex !== state.icQuestionnaireIndex) {
          return;
        }
      }

      if (state.questionnaireIndex >= 1) {
        state.questionnaireIndex -= 1;
      }

      commit('setQuestionnaireIndex', state.questionnaireIndex);
      commit('setSelectedQuestion');
      commit('setSelectedQuestionType');
      commit('setForceInput');
      commit('setMultipleChoose');
      if (state.selectedQuestionType === 'IntegrativeComplexityQuestion') {
        commit('setIcTextQuestionWeight');
        commit('setInitIcQuestions');
        commit('setIcQuestionnaireIndex', state.icQuestions.length - 1);
        commit('setSelectedIcQuestion');
        commit('setSelectedIcQuestionType');
      } else {
        commit('removeSelectedIcQuestionType');
      }
      commit('initAnswer');
      commit('setInvolvedUsers');
      commit('setSelectedQuestionProgressText');
      commit('setSelectedQuestionChapterText');
    },
  },
};
