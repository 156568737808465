<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-message">{{ message }}</div>
      <div class="popup-buttons">
        <button class="popup-cancel-button" @click="cancel">{{ cancelButtonText }}</button>
        <button class="popup-confirm-button" @click="confirm">{{ confirmButtonText }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopUpConfirmationComponent',
  props: {
    message: {
      type: String,
      required: true,
    },
    confirmButtonText: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: true,
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.popup-message {
  font-size: 18px;
  margin-bottom: 10px;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
}

.popup-cancel-button, .popup-confirm-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-cancel-button {
  background-color: #ccc;
  color: white;
}

.popup-confirm-button {
  background-color: #007bff;
  color: white;
}
</style>
