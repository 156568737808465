<template>
  <div class="input-container">
    <div class="pill-container">
      <div v-for="(selectedParticipant) in selectedParticipants" :key="selectedParticipant"
           class="pill-element">
        {{ selectedParticipant.name }}
        <span class="close" @click.prevent="checkElement(selectedParticipant)">
          <font-awesome-icon icon="fa-trash-can"/>
        </span>
      </div>
      <div class="search-pill-element">
        <input v-model="searchTerm"
               class="search-input" type="text"
               id="search-input" ref="searchInput"
               :placeholder="$t('KssParticipantsSelectionComponent.placeholder.searchInput')"/>
      </div>
    </div>
  </div>
  <ul v-if="kssParticipants.length >0" class="list-items" id="tpl_teams-container">
    <li class="item" v-for="(participant) in kssParticipants"
        :key="participant"
        @click.prevent="checkElement(participant)">
              <span class="item-text">
                {{ participant.name }}
              </span>
      <span class="checkbox" />
    </li>
  </ul>
  <ul v-else class="list-items">
    <li class="item-disabled">
      <span class="item-item-disabled-text">
        {{ $t('KssParticipantsSelectionComponent.placeholder.noParticipants') }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'KssParticipantsSelectionComponent',
  data() {
    return {
      searchTerm: '',
    };
  },
  computed: {
    kssParticipants() {
      return this.$store.getters.getKssParticipants
        .filter((el) => !el.isChecked)
        .filter((el) => el.name.toLowerCase()
          .includes(this.searchTerm.toLowerCase()))
        .sort((a, b) => ((a.name > b.name) ? 1 : -1));
    },
    selectedParticipants() {
      return this.$store.getters.getKssParticipants.filter((el) => el.isChecked);
    },
  },
  methods: {
    checkElement(participant) {
      const index = this.$store.getters.getKssParticipants
        .findIndex((item) => item._id === participant._id);
      this.$store.commit('updateKssParticipantSelection', index);
    },
  },
};
</script>

<style scoped>
.list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.select-container .list-items {
  width: 100%;
  max-height: 250px;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background-color: var(--color-milk);
  display: block;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 10;
}

.select-container .list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
}

.select-container .list-items .item:hover {
  transform: scale(1.02);
}

.select-container .list-items .item .item-text {
  display: flex;
  align-items: center;
}

.select-container .list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.select-container .list-items .item-disabled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: not-allowed;
  transition: 0.3s;
  opacity: 0.5;
}

.select-container .list-items .item .item-disabled-text {
  display: flex;
  align-items: center;
}

.input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.pill-element {
  display: flex;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px;
  color: var(--color-milk);
}

.pill-element .close {
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

</style>
