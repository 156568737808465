<template>
  <p id="question-text" class="question-text">
    {{ question.properties.content.text_question[getLocale] ?? '' }}
    <span v-if="getHoverInfo" class="icon-container">
      <i class="fas fa-info-circle">
        <font-awesome-icon icon="fa-circle-question"/>
      </i>
      <span class="hover-bubble">{{ getHoverInfo }}</span>
    </span>
  </p>
  <p class="question-text choose-user">
    {{ $t('KssChampionUserListQuestion.beforeNumber') }}
    <span style='color: var(--color-orange);'>{{ getSelectedUserCount }}</span>
    {{ $t('KssChampionUserListQuestion.afterNumber', { count: getSelectedUserCount }) }}
  </p>
  <div class="container">
    <kss-champion-profile v-for="(member, i) in involvedUsers" :key="member._id"
                          @click.prevent="select(i)" :is-selected="member.isChecked"
                          :name="member.name"
                          :picture-url="member.picture_profile"/>
  </div>
  <div class="btns">
    <button type="button" class="btn-choose" name="update" :disabled="submitDisabled"
            @click.prevent="handleChoice(false)">
      {{ $t('KssChampionUserListQuestion.choose') }}
    </button>
    <button type="button" class="btn-next-time" name="update" :disabled="submitDisabled"
            @click.prevent="handleChoice(true)">
      {{ $t('KssChampionUserListQuestion.maybeNextTime') }}
    </button>
  </div>
</template>

<script>
import KssChampionProfile from '@/components/Home/QuestionnaireComponent/KssChampionProfile.vue';

export default {
  name: 'KssChampionUserListQuestion',
  components: { KssChampionProfile },
  data() {
    return {
      question: this.$store.state.questionnaireManagement.selectedQuestion,
      involvedUsers: this.$store.getters.getInvolvedUsers,
      submitDisabled: false,
    };
  },
  computed: {
    getLocale() {
      return this.$i18n.locale;
    },
    getHoverInfo() {
      return this.question.properties.content.description?.[this.getLocale];
    },
    getSelectedUserCount() {
      return this.$store.state.questionnaireManagement.multipleChoose
        - this.$store.getters.getInvolvedUsers.filter((el) => el.isChecked).length;
    },
    getForceInput() {
      return this.$store.state.questionnaireManagement.forceInput;
    },
    getAnswer() {
      return this.$store.state.questionnaireManagement.answer;
    },
  },
  methods: {
    select(index) {
      this.$store.commit('selectInvolvedUser', index);
    },
    async handleChoice(skipAnswering) {
      try {
        this.submitDisabled = true;
        if (this.getForceInput) {
          if (!this.getAnswer) {
            this.$store.commit('setToastNotification', {
              id: Math.random(),
              message: this.$t('ToastComponenet.questionnaire.noInputErr'),
              type: 'error',
            });
            this.submitDisabled = false;
            return;
          }
        }
        if (skipAnswering) {
          this.$store.commit('setAnswer', undefined);
        }
        await this.$store.dispatch('setNextQuestion');
        this.submitDisabled = false;
      } catch (error) {
        this.submitDisabled = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
  },
  mounted() {
    this.$store.commit('setShowNextButton', false);
  },
  unmounted() {
    this.$store.commit('setShowNextButton', true);
  },
};
</script>

<style scoped>
.question-text {
  text-align: center;
  padding: 2rem 0;
}

.question-text.choose-user {
  font-weight: bold;
}

.container {
  width: 100%;
  max-width: 600px;
  max-height: 400px;
  margin: 0 auto;
  padding: 20px 15px;
  word-break: break-all;
  overflow-y: auto;
}

.btns {
  margin-top: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
}

.btn-choose,
.btn-next-time {
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-milk);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin: 0 5px;
}

.btn-choose {
  background-color: var(--color-orange);
}

.btn-next-time {
  background-color: var(--color-milk);
  color: var(--color-black);
  border: 2px solid var(--color-orange);
}

.btn-choose:hover,
.btn-next-time:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.icon-container {
  position: relative;
  display: inline-block;
}

.hover-bubble {
  visibility: hidden;
  width: 150px;
  background-color: var(--color-orange);
  color: var(--color-milk);;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -75px;
}

.icon-container:hover .hover-bubble {
  visibility: visible;
}

.icon-container:hover i {
  color: var(--color-orange);
  transform: scale(1.2);
}

@media (max-width: 600px) {
  .btns {
    flex-direction: column;
    align-items: center;
  }

  .btns button {
    margin: 5px 0;
  }
}
</style>
