<template>
  <div :class="`single-popup single-popup-${toast.type}`" id="toast_notification">
    <div class="text">
      <div class="headline">
        <h3>
          <span id="tpl_newTask_popup_firstName">
          {{ $t('ToastComponenet.' + toast.type )}}
          </span>
        </h3>
        <div class="close" id="btn_close">
          <i @click.prevent="closeToast">
            <font-awesome-icon icon="fa-xmark"/>
          </i>
        </div>
      </div>
      <div class="description">
        <p>
          <span id="tpl_newTask_popup_text">
            {{ toast.message }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToastComponent',
  props: ['toast'],
  data() {
    return {
      timeout: 6000,
    };
  },
  methods: {
    closeToast() {
      this.$store.commit('removeToastNotification', this.toast.id);
    },
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit('removeToastNotification', this.toast.id);
    }, this.timeout);
  },
};
</script>

<style scoped>
.single-popup {
  display: block;
  padding: 10px 16px;
  margin: 7px 0;
  border-radius: var(--border-radius);
  box-shadow: 5px 10px 8px var(--color-light);
}

.single-popup-success {
  background-color: var(--color-success);
}

.single-popup-error {
  background-color: var(--color-error);
}

.single-popup-warning {
  background-color: var(--color-yellow-orange);
}

.single-popup .text {
  padding: 20px;
  color: white;
}

.single-popup .text .headline {
  display: flex;
  justify-content: space-between;
}

.single-popup .text .headline .close {
  margin-top: -16px;
  margin-right: -16px;
  cursor: pointer;
}

.single-popup .text .headline .close:hover {
  color: var(--color-orange);
}

.single-popup .text .headline h3 {
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
  color: white;
}

summary {
  padding: 20px 0;
  color: var(--color-orange);
  cursor: pointer;
}

/*Scrollbar*/
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

@media screen and (min-width: 431px) and (max-width: 500px) {
  .single-popup {
    border-radius: 1rem;
  }

  .single-popup .text {
    padding: 10px;
    text-align: center;
  }

  .single-popup .text .headline h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .single-popup .text .headline .close {
    margin-top: -14px;
    margin-right: -14px;
  }

  .single-popup .text .description {
    font-size: 14px;
  }

  .single-popup .text .footline {
    font-size: 12px;
    float: right;
    color: var(--color-disabled);
  }

  .accordeon {
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    font-size: 12px;
  }

  .accordeon::-webkit-scrollbar {
    width: 2px;
    margin-left: 5px;
    background: var(--color-white);
    border-radius: 0 8px 8px 0;
  }

  .accordeon::-webkit-scrollbar-thumb {
    background: var(--color-disabled);
    border-radius: 0 8px 8px 0;
  }
}

</style>
