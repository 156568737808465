<template>
  <div class="rounded-box" :class="{'completed': reached}">
    <div class="weekgoal-title">{{ getTitle }}</div>
    <div class="tasks-count">{{ doneTasks }}/{{ totalTasks }}</div>
  </div>
</template>

<script>
export default {
  name: 'WeekgoalComponent',
  props: {
    id: {
      type: String,
      required: true,
    },
    doneTasks: {
      type: Number,
      required: true,
    },
    totalTasks: {
      type: Number,
      required: true,
    },
    reached: {
      type: Boolean,
      required: true,
    },
    representationProperties: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getTitle() {
      if (this.representationProperties?.critereaName
        && Object.prototype
          .hasOwnProperty.call(this.representationProperties?.critereaName, this.getLocale)) {
        return this.representationProperties?.critereaName[this.getLocale];
      }
      return this.$t(`WeekgoalComponent.${this.id}`);
    },
    getLocale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped>

.rounded-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  max-width: 300px;
  min-width: 200px;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  background-color: #b3b3b3;
  color: white;
  font-family: Arial, sans-serif;
  flex: 1;
  margin: 10px;
}

.rounded-box.completed {
  background-color: #c8f7c5;
}

.weekgoal-title {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 21px;
  font-weight: bold;
}

.tasks-count {
  font-size: 48px;
  font-weight: bold;
}

@media (max-width: 480px) {
  .rounded-box {
    width: 150px;
    height: 80px;
  }

  .rounded-box .weekgoal-title {
    font-size: 16px;
  }

  .rounded-box .tasks-count {
    font-size: 20px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .rounded-box .weekgoal-title {
    font-size: 18px;
  }

  .rounded-box .tasks-count {
    font-size: 24px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .rounded-box .weekgoal-title {
    font-size: 21px;
  }

  .rounded-box .tasks-count {
    font-size: 36px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .rounded-box .weekgoal-title {
    font-size: 21px;
  }

  .rounded-box .tasks-count {
    font-size: 48px;
  }
}

@media (min-width: 1201px) {
  .rounded-box .weekgoal-title {
    font-size: 21px;
  }

  .rounded-box .tasks-count {
    font-size: 48px;
  }
}

</style>
