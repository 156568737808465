<template>
  <div class="container">
    <div class="forms">
      <div class="content">
        <img class="platform-logo" src="@/assets/logo_transparent.png" alt="txt">
        <h1>{{ $t('LoginComponent.welcomeMessage') }}</h1>
        <language-component class="lang-component" v-if="true"/>
        <div>
          <button class="button" type="button" name="login" id="btn_login" :disabled="isSubmitting"
                  @click="login()">
            <font-awesome-icon icon="fa-brands fa-microsoft"/>
            {{ $t('LoginComponent.login') }}
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import LanguageComponent from '@/components/global/LanguageComponent.vue';
import keycloak from '../../../keycloak';

export default {
  name: 'LogoutComponent',
  components: { LanguageComponent },
  data() {
    return {
      isSubmitting: false,
    };
  },
  methods: {
    async login() {
      this.isSubmitting = true;
      await keycloak.login({ idpHint: 'oidc' });
      this.isSubmitting = false;
    },
  },
  mounted() {
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.$store.commit('setLanguage', lang);
      this.$root.$i18n.locale = lang;
    }
  },
};
</script>

<style scoped>
h1 {
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 25px;
  /*font-weight: bold;*/
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
  padding: 0 0 0 0;
}

p {
  text-align: center;
  margin: 2rem 0;
  padding: 0 10px;
}

/*------------ FORM ----------------*/
.container .forms {
  padding: 30px;
}

.platform-logo {
  display: block;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  width: 10rem;
  height: auto;
}

.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.button:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

button[type=button]:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.lang-component {
  margin-top: 25px;
}

/*==================== MEDIA QUERIES =====================*/
@media screen and (max-width: 500px) {
  .container {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
}

</style>
